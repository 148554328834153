import { createSlice } from '@reduxjs/toolkit';
import _unionBy from 'lodash/unionBy';
import initialState from './initialState';

const portfolioSlice = createSlice({
  name: 'portfolio',
  initialState,
  reducers: {
    startLoadingPortfolios: (state) => ({
      ...state,
      loadingPortfolios: true,
    }),
    endLoadingPortfolios: (state) => ({
      ...state,
      loadingPortfolios: false,
    }),
    setPortfolios: (state, action) => {
      const portfolios = action.payload;
      return {
        ...state,
        portfolios,
      };
    },
    addPortfolios: (state, action) => {
      const portfolios = action.payload;
      return {
        ...state,
        portfolios: _unionBy(portfolios, state.portfolios, 'id'),
      };
    },
    setPortfoliosNextPageIndex: (state, action) => {
      const nextPageIndex = action.payload;
      return {
        ...state,
        nextPageIndex,
      };
    },
    setPortfoliosTotal: (state, action) => {
      const portfoliosTotal = action.payload;
      return {
        ...state,
        portfoliosTotal,
      };
    },
    setPortfolioDetails: (state, action) => {
      const portfolioDetails = action.payload;
      return {
        ...state,
        portfolioDetails,
      };
    },
    resetPortfolioState: () => initialState,
  },
});

export const {
  startLoadingPortfolios,
  endLoadingPortfolios,
  setPortfolios,
  addPortfolios,
  setPortfoliosNextPageIndex,
  setPortfoliosTotal,
  setPortfolioDetails,
  resetPortfolioState,
} = portfolioSlice.actions;

export const getLoadingPortfolios = (state) => state.portfolio.loadingPortfolios;

export const getPortfoliosTotal = (state) => state.portfolio.portfoliosTotal;

export const getPortfolios = (state) => state.portfolio.portfolios;

export const getPortfolioDetails = (state) => state.portfolio.portfolioDetails;

export const { reducer } = portfolioSlice;

const TOKEN_KEY = 'token';

export default class TokenRepository {
  static get() {
    return localStorage.getItem(TOKEN_KEY);
  }

  static save(token) {
    if (token) {
      localStorage.setItem(TOKEN_KEY, token);
    } else {
      localStorage.setItem(TOKEN_KEY, '');
    }
  }

  static remove() {
    localStorage.removeItem(TOKEN_KEY);
  }
}

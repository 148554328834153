import { v4 as uuidv4 } from 'uuid';
import { TOAST_STATUS } from '../../Common/const';

export default class Toast {
  constructor(parameters) {
    const {
      uuid,
      messageKey,
      message,
      status = TOAST_STATUS.DEFAULT,
    } = parameters;
    this.uuid = uuid || uuidv4();
    this.messageKey = messageKey;
    this.message = message;
    this.status = status;
  }
}

import _get from 'lodash/get';
import _invoke from 'lodash/invoke';
import axios from 'axios';
import TokenRepository from '../LocalStorage/TokenRepository';
import { AUTHORIZATION_HEADER } from '../../../Common/const';

function createSession() {
  const session = axios.create({
    baseURL: new URL('/api', process.env.REACT_APP_BACKEND_URL).toString(),
  });

  session.interceptors.request.use((config) => {
    const token = TokenRepository.get();
    if (token) {
      // eslint-disable-next-line no-param-reassign
      config.headers[AUTHORIZATION_HEADER] = `bearer ${token}`;
    }
    return config;
  });

  return session;
}

class _HttpClient {
  constructor() {
    this.session = createSession();

    this.session.interceptors.response.use(
      (response) => response,
      (error) => {
        const originalRequest = error.config;
        if (_get(error, 'response.status') === 401) {
          if (
            !!error.response.headers[AUTHORIZATION_HEADER]
            // eslint-disable-next-line no-underscore-dangle
            && !originalRequest._retry
          ) {
            // eslint-disable-next-line no-underscore-dangle
            originalRequest._retry = true;
            const token = error.response.headers[AUTHORIZATION_HEADER]?.replace(/bearer /i, '');
            TokenRepository.save(token);
            return this.session(originalRequest);
          }

          TokenRepository.remove();
          _invoke(this, 'unauthorizedListener');
        }

        return Promise.reject(error);
      },
    );
  }

  setUnauthorizedListener = (listener) => {
    this.unauthorizedListener = listener;
  };

  get = async (...params) => this.session.get(...params);

  post = async (...params) => this.session.post(...params);

  put = async (...params) => this.session.put(...params);

  delete = async (...params) => this.session.delete(...params);
}

const HttpClient = new _HttpClient();

export default HttpClient;

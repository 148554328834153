import store from '../Store/store';
import { deleteToastMessage, enqueueToastMessage } from '../Store/Session/slice';
import Toast from '../Models/Toast';
import { ERROR_TYPES, TOAST_STATUS } from '../../Common/const';

export default class ToastService {
  static createNetworkDangerToast(result, messageKey) {
    let translateKey = messageKey;

    if (result.errorType === ERROR_TYPES.UNAUTHORIZED) {
      translateKey = 'validation.unauthorized';
    } else if (result.errorType === ERROR_TYPES.NOT_FOUND) {
      translateKey = 'validation.notFound';
    } else if (result.errorType === ERROR_TYPES.FORBIDDEN) {
      translateKey = 'validation.forbidden';
    } else if (result.errorType === ERROR_TYPES.NETWORK_ERROR) {
      translateKey = 'validation.networkError';
    }

    const toast = new Toast({
      messageKey: translateKey,
      status: TOAST_STATUS.DANGER,
    });

    store.dispatch(enqueueToastMessage(toast));
  }

  static enqueueToast(toast) {
    if (toast instanceof Toast) {
      store.dispatch(enqueueToastMessage(toast));
    } else {
      throw new Error('toast no instanceof Toast');
    }
  }

  static deleteToast(uuid) {
    store.dispatch(deleteToastMessage(uuid));
  }
}

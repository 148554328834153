import _filter from 'lodash/filter';
import { createSlice } from '@reduxjs/toolkit';
import initialState from './initialState';

const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    removeToken: (state) => ({
      ...state,
      token: undefined,
    }),
    setToken: (state, action) => {
      const token = action.payload;
      return {
        ...state,
        token,
      };
    },
    enqueueToastMessage: (state, action) => {
      const toast = action.payload;

      return {
        ...state,
        toastMessages: [...state.toastMessages, toast],
      };
    },
    deleteToastMessage: (state, action) => {
      const toastUuid = action.payload;
      return {
        ...state,
        toastMessages: _filter(state.toastMessages, (toast) => toast.uuid !== toastUuid),
      };
    },
  },
});

export const {
  removeToken,
  setToken,
  enqueueToastMessage,
  deleteToastMessage,
} = sessionSlice.actions;

export const islLogIn = (state) => !!state.session.token;

export const getToken = (state) => state.session.token;

export const getToastMessages = (state) => state.session.toastMessages;

export const { reducer } = sessionSlice;

const ID = 'id';
const DESCRIPTION = 'text';

export default class Feedback {
  constructor(parameters) {
    const {
      id, description,
    } = parameters;
    this.id = id;
    this.description = description;
  }

  static fromApi(object = {}) {
    return new Feedback({
      id: object[ID],
      description: object[DESCRIPTION],
    });
  }

  toApi() {
    return {
      [ID]: this.id,
      [DESCRIPTION]: this.description,
    };
  }
}

import HttpClient from './HttpClient';
import parseResponse from './parseResponse';
import Project from '../../Models/Project';

export default class ProjectRepository {
  static async create(portfolioId, project) {
    return HttpClient.post(`portfolios/${portfolioId}/projects`, new Project(project).toApi()).then(
      (response) => parseResponse(response, Project.fromApi),
      ({ response }) => parseResponse(response, undefined, Project.parseErrors),
    );
  }

  static async update(portfolioId, project) {
    return HttpClient.put(`portfolios/${portfolioId}/projects/${project.id}`, new Project(project).toApi()).then(
      (response) => parseResponse(response, Project.fromApi),
      ({ response }) => parseResponse(response, undefined, Project.parseErrors),
    );
  }

  static async delete(portfolioId, project) {
    return HttpClient.delete(`portfolios/${portfolioId}/projects/${project.id}`).then(
      (response) => parseResponse(response),
      ({ response }) => parseResponse(response),
    );
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import { Form as FinalForm } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import styles from './ProjectForm.module.scss';
import FieldInput from '../FieldInput/FieldInput';
import { requiredValidate } from '../../validators';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import { ReactComponent as DeleteSvg } from '../../Images/Delete.svg';
import IconButton from '../IconButton/IconButton';
import { MEDIA_TYPES } from '../../const';

function ProjectForm({
  initialValues, projectName, onSubmit, onDeleteProject,
}) {
  const { t } = useTranslation('translation');

  return (
    <FinalForm
      onSubmit={onSubmit}
      initialValues={initialValues}
      mutators={{
        ...arrayMutators,
      }}
      render={({
        handleSubmit, submitError, submitting, form: { mutators: { push } }, dirty,
      }) => (
        <form onSubmit={handleSubmit} className={styles.form}>
          {onDeleteProject ? (
            <IconButton
              className={styles.form__delete_project}
              onClick={onDeleteProject}
            >
              <DeleteSvg />
            </IconButton>
          ) : null}
          <h2 className={styles.form__title}>
            <div className={styles.form__projectTitle}>{projectName || t('pageForm.formTitle')}</div>
            {dirty ? <div> *</div> : null}
          </h2>
          <FieldInput
            fieldName="title"
            label={t('pageForm.title')}
            placeholder={t('pageForm.titlePlaceholder')}
            validate={requiredValidate}
          />
          <FieldInput
            fieldName="projectUrl"
            label={t('pageForm.projectUrl')}
            placeholder={t('pageForm.projectUrlPlaceholder')}
          />
          <FieldInput
            fieldName="description"
            label={t('pageForm.description')}
            placeholder={t('pageForm.descriptionPlaceholder')}
          />
          <div className={styles.images_title}>
            <div className={styles.images_title__text}>
              {t('pageForm.mediaUrls')}
            </div>
            <button
              type="button"
              className={styles.images_title__button}
              disabled={submitting}
              onClick={() => push('mediaUrls', {
                type: MEDIA_TYPES.IMAGE,
              })}
            >
              {t('pageForm.addMediaUrl')}
            </button>
          </div>
          <FieldArray name="mediaUrls">
            {({ fields }) => fields.map((name, index) => (
              <div
                key={name}
                className={styles.image_box}
              >
                <FieldInput
                  fieldName={`${name}.url`}
                  className={styles.image_box__input}
                  placeholder={t('pageForm.mediaUrlsPlaceholder')}
                />
                <button
                  className={classNames(
                    styles.image_box__select_button,
                    { [styles.select]: fields.value[index].type === MEDIA_TYPES.IMAGE },
                  )}
                  type="button"
                  onClick={() => {
                    fields.update(index, {
                      ...fields.value[index],
                      type: MEDIA_TYPES.IMAGE,
                    });
                  }}
                >
                  Image
                </button>
                <button
                  className={classNames(
                    styles.image_box__select_button,
                    { [styles.select]: fields.value[index].type === MEDIA_TYPES.VIDEO },
                  )}
                  type="button"
                  onClick={() => {
                    fields.update(index, {
                      ...fields.value[index],
                      type: MEDIA_TYPES.VIDEO,
                    });
                  }}
                >
                  Video
                </button>
                <IconButton
                  className={styles.image_box__button}
                  onClick={() => fields.remove(index)}
                >
                  <DeleteSvg />
                </IconButton>
              </div>
            ))}
          </FieldArray>
          <ErrorMessage content={submitError ? t(submitError) : undefined} />
          <button
            type="submit"
            className={styles.form__submit}
            disabled={submitting}
          >
            {t('pageForm.submitTitle')}
          </button>
        </form>
      )}
    />
  );
}

ProjectForm.propTypes = {
  initialValues: PropTypes.object,
  projectName: PropTypes.string,
  onSubmit: PropTypes.func,
  onDeleteProject: PropTypes.func,
};

ProjectForm.defaultProps = {
  initialValues: undefined,
  projectName: '',
  onSubmit: undefined,
  onDeleteProject: undefined,
};

export default ProjectForm;

import PortfolioRepository from '../Repositories/Api/PortfolioRepository';
import store from '../Store/store';
import {
  endLoadingPortfolios,
  setPortfolioDetails,
  setPortfolios, setPortfoliosNextPageIndex,
  setPortfoliosTotal,
  startLoadingPortfolios,
} from '../Store/Portfolio/slice';
import ShareLinkRepository from '../Repositories/Api/ShareLinkRepository';
import Portfolio from '../Models/Portfolio';

export default class PortfolioService {
  static async reload() {
    store.dispatch(setPortfolios([]));
    store.dispatch(startLoadingPortfolios());

    const result = await PortfolioRepository.getAllByPage(0);
    if (!result.status) {
      store.dispatch(endLoadingPortfolios());
      return result;
    }

    store.dispatch(setPortfolios(result.data.portfolios));
    store.dispatch(setPortfoliosTotal(result.data.portfoliosTotal));
    store.dispatch(setPortfoliosNextPageIndex(1));
    store.dispatch(endLoadingPortfolios());

    return { status: true };
  }

  static async loadMore() {
    const state = store.getState();
    const { nextPageIndex, loadingAchievements: loadingPortfolios } = state.portfolio;

    if (loadingPortfolios) return { status: false };

    store.dispatch(startLoadingPortfolios());

    const result = await PortfolioRepository.getAllByPage(nextPageIndex);
    if (!result.status) {
      store.dispatch(endLoadingPortfolios());
      return result;
    }

    store.dispatch(setPortfolios(result.data.portfolios));
    store.dispatch(setPortfoliosTotal(result.data.portfoliosTotal));
    store.dispatch(setPortfoliosNextPageIndex(nextPageIndex + 1));
    store.dispatch(endLoadingPortfolios());

    return { status: true };
  }

  static async loadDetails(portfolioId) {
    const result = await PortfolioRepository.getById(portfolioId);
    if (!result.status) {
      store.dispatch(setPortfolioDetails(undefined));
      return result;
    }

    store.dispatch(setPortfolioDetails(result.data));

    return { status: true };
  }

  static async loadDetailsByShareLinkId(shareLinkId) {
    const result = await PortfolioRepository.getByShareLinkId(shareLinkId);
    if (!result.status) {
      store.dispatch(setPortfolioDetails(undefined));
      return result;
    }

    store.dispatch(setPortfolioDetails(result.data));

    return { status: true };
  }

  static async clearDetails() {
    store.dispatch(setPortfolioDetails(undefined));
  }

  static async create(portfolio) {
    return PortfolioRepository.create(portfolio);
  }

  static async update(portfolio) {
    const result = await PortfolioRepository.update(portfolio);
    if (result.status) {
      const portfolioDetailsResult = await PortfolioRepository.getById(portfolio.id);
      if (portfolioDetailsResult.status) {
        store.dispatch(setPortfolioDetails(portfolioDetailsResult.data));
      }
    }
    return result;
  }

  static async delete(portfolio) {
    return PortfolioRepository.delete(portfolio);
  }

  static async createShareLink(portfolio, shareLink) {
    const result = await ShareLinkRepository.create(portfolio, shareLink);
    if (result.status) {
      const newShareLink = result.data;
      const state = store.getState();
      const { portfolioDetails } = state.portfolio;
      const newPortfolioDetails = new Portfolio({
        ...portfolioDetails,
        shareLinks: [...portfolioDetails.shareLinks, newShareLink],
      });
      store.dispatch(setPortfolioDetails(newPortfolioDetails));
    }
    return result;
  }

  static async deleteShareLink(portfolio, shareLink) {
    const result = await ShareLinkRepository.delete(portfolio, shareLink);
    if (result.status) {
      const state = store.getState();
      const { portfolioDetails } = state.portfolio;
      const newPortfolioDetails = new Portfolio({
        ...portfolioDetails,
        shareLinks: portfolioDetails.shareLinks.filter(({ id }) => id !== shareLink.id),
      });
      store.dispatch(setPortfolioDetails(newPortfolioDetails));
    }
    return result;
  }
}

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Pagination } from '@egjs/flicking-plugins';
import Flicking, { ViewportSlot } from '@egjs/react-flicking';
import styles from './ProjectPage.module.scss';
import ProjectMedia from '../ProjectMedia/ProjectMedia';
import { MEDIA_TYPES } from '../../const';

function ProjectPage({
  title, description, media, className, onClick,
}) {
  const plugins = useMemo(() => [new Pagination()], []);

  return (
    <button className={classNames(styles.page, className)} type="button" onClick={onClick}>
      <div className={styles.page__ratio_box}>
        <div className={styles.page__content}>
          <div className={styles.page__mediaCounter}>
            {media.length}
          </div>
          <Flicking
            plugins={plugins}
            circular
          >
            {media.map(({ url, type }) => (
              <ProjectMedia
                key={url}
                className={styles.page__media}
                mediaType={type}
                mediaUrl={url}
              />
            ))}
            {media.length === 0 ? <ProjectMedia className={styles.page__media} /> : null}
            <ViewportSlot>
              <div className="flicking-pagination" />
            </ViewportSlot>
          </Flicking>
          <div className={styles.page__title_box}>
            <div className={styles.page__title}>
              { title }
            </div>
            <div className={styles.page__description}>
              { description }
            </div>
          </div>
        </div>
      </div>
    </button>
  );
}

ProjectPage.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  media: PropTypes.arrayOf(PropTypes.shape({
    url: PropTypes.string.isRequired,
    type: PropTypes.oneOf(Object.values(MEDIA_TYPES)).isRequired,
  })),
  className: PropTypes.string,
  onClick: PropTypes.func,
};

ProjectPage.defaultProps = {
  media: [],
  className: '',
  onClick: undefined,
};

export default ProjectPage;

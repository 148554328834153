import { configureStore } from '@reduxjs/toolkit';
import { reducer as sessionReducer } from './Session/slice';
import { reducer as portfolioReducer } from './Portfolio/slice';

export default configureStore({
  reducer: {
    session: sessionReducer,
    portfolio: portfolioReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  }),
});

import _invoke from 'lodash/invoke';
import Project from './Project';
import ShareLink from './ShareLink';

const ID = 'id';
const TITLE = 'title';
const IMAGE_URL = 'imageUrl';
const USER_URL = 'userUrl';
const IS_PUBLIC = 'isPublic';
const PROJECTS = 'projects';
const SHARE_LINKS = 'shareLinks';

const ERROR_TITLE = 'Title';
const ERROR_IMAGE_URL = 'ImageUrl';
const ERROR_USER_URL = 'UserUrl';
const ERROR_IS_PUBLIC = 'IsPublic';

export default class Portfolio {
  constructor(parameters) {
    const {
      id,
      title,
      imageUrl,
      userUrl,
      isPublic,
      projects,
      shareLinks,
    } = parameters;
    this.id = id;
    this.title = title;
    this.imageUrl = imageUrl;
    this.userUrl = userUrl;
    this.isPublic = !!isPublic;
    this.projects = projects;
    this.shareLinks = shareLinks;
  }

  toApi() {
    return {
      [ID]: this.id,
      [TITLE]: this.title,
      [IMAGE_URL]: this.imageUrl,
      [USER_URL]: this.userUrl,
      [IS_PUBLIC]: this.isPublic,
    };
  }

  static fromApi(object = {}) {
    return new Portfolio(
      {
        id: object[ID],
        title: object[TITLE],
        imageUrl: object[IMAGE_URL],
        userUrl: object[USER_URL],
        isPublic: object[IS_PUBLIC],
        projects: _invoke(object, [PROJECTS, 'map'], Project.fromApi),
        shareLinks: _invoke(object, [SHARE_LINKS, 'map'], ShareLink.fromApi),
      },
    );
  }

  static parseErrors(object = {}) {
    return {
      title: object[ERROR_TITLE],
      imageUrl: object[ERROR_IMAGE_URL],
      userUrl: object[ERROR_USER_URL],
      isPublic: object[ERROR_IS_PUBLIC],
    };
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { islLogIn } from '../../DataCore/Store/Session/slice';

function RequireAuth({ children }) {
  const isLogIn = useSelector(islLogIn);

  if (isLogIn) {
    return children;
  }

  return (
    <Navigate to="/SignIn" replace />
  );
}

RequireAuth.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.array,
    PropTypes.string,
  ]).isRequired,
};

export default RequireAuth;

import _get from 'lodash/get';
import { i18n } from '../i18n';

export const requiredValidate = (value) => (value ? undefined : i18n.t('validation.required'));

export const maxLengthValidate = (max, path) => (value) => {
  const v = _get(value, path, value);
  return (typeof v !== 'string' || v.length <= max ? undefined : i18n.t('validation.maxLength', { max }));
};

// eslint-disable-next-line max-len
export const composeValidators = (...validators) => (value) => validators.reduce((error, validator) => error || validator(value), undefined);

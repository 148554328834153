import FeedbackRepository from '../Repositories/Api/FeedbackRepository';

export default class FeedbackService {
  static async create(feedback) {
    const result = await FeedbackRepository.create(feedback);
    if (!result.status) {
      return result;
    }

    return { status: true };
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as DeleteSvg } from '../../Images/Delete.svg';
import { ReactComponent as CopySvg } from '../../Images/Copy.svg';
import IconButton from '../IconButton/IconButton';
import styles from './LinkRow.module.scss';

function LinkRow({ link, onDelete, onCopy }) {
  return (
    <div className={styles.box}>
      <input className={styles.input} value={link} disabled />
      <IconButton onClick={onCopy}><CopySvg /></IconButton>
      <IconButton
        className={styles.delete}
        onClick={onDelete}
      >
        <DeleteSvg />
      </IconButton>
    </div>
  );
}

LinkRow.propTypes = {
  link: PropTypes.string.isRequired,
  onCopy: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default LinkRow;

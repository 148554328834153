import React from 'react';
import PropTypes from 'prop-types';
import { MEDIA_TYPES } from '../../const';
import imgPlaceholder from '../../Images/imgPlaceholder.svg';
import styles from './ProjectMedia.module.scss';

function stopDragHandler(event) {
  event.preventDefault();
}

const ProjectMedia = React.forwardRef(({
  mediaUrl, mediaType, className, autoPlay, muted,
}, ref) => (
  <div ref={ref} className={className}>
    <div className={styles.media}>
      {mediaType === MEDIA_TYPES.VIDEO
        // eslint-disable-next-line jsx-a11y/media-has-caption
        ? <video autoPlay={autoPlay} muted={muted} loop controls={false} src={mediaUrl} />
        : <img onDragStart={stopDragHandler} src={mediaUrl || imgPlaceholder} alt="" />}
    </div>
  </div>
));

ProjectMedia.propTypes = {
  autoPlay: PropTypes.bool,
  muted: PropTypes.bool,
  mediaUrl: PropTypes.string,
  mediaType: PropTypes.oneOf(Object.values(MEDIA_TYPES)),
  className: PropTypes.string,
};

ProjectMedia.defaultProps = {
  autoPlay: false,
  muted: true,
  mediaUrl: '',
  mediaType: undefined,
  className: '',
};

export default ProjectMedia;

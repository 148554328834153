import { FORM_ERROR } from 'final-form';
import _mapValues from 'lodash/mapValues';
import _omitBy from 'lodash/omitBy';
import _head from 'lodash/head';
import { ERROR_TYPES } from './const';

// eslint-disable-next-line import/prefer-default-export
export const parseFormErrors = (result) => {
  if (result.errorType === ERROR_TYPES.BAD_REQUEST) {
    return {
      [FORM_ERROR]: 'validation.badRequest',
      ..._mapValues(
        _omitBy(result.error, (value) => !value),
        (value) => (Array.isArray(value) ? _head(value) : value),
      ),
    };
  }
  if (result.errorType === ERROR_TYPES.UNAUTHORIZED) {
    return { [FORM_ERROR]: 'validation.unauthorized' };
  }
  if (result.errorType === ERROR_TYPES.NOT_FOUND) {
    return { [FORM_ERROR]: 'validation.notFound' };
  }
  if (result.errorType === ERROR_TYPES.FORBIDDEN) {
    return { [FORM_ERROR]: 'validation.forbidden' };
  }
  if (result.errorType === ERROR_TYPES.NETWORK_ERROR) {
    return { [FORM_ERROR]: 'validation.networkError' };
  }
  return { [FORM_ERROR]: 'validation.errorRequest' };
};

import React, { useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import _get from 'lodash/get';
import PortfolioService from '../../DataCore/Services/PortfolioService';
import { parseFormErrors } from '../../Common/func';
import PortfolioForm from '../../Common/Components/PortfolioForm/PortfolioForm';
import Button from '../../Common/Components/Button/Button';
import styles from './CreatePortfolioPage.module.scss';

const defaultValues = {
  title: '',
  imageUrl: '',
  userUrl: '',
};

function CreatePortfolioPage() {
  const navigate = useNavigate();
  const { t } = useTranslation('translation', { keyPrefix: 'portfolioDetails' });

  const onSubmit = useCallback(async (values) => {
    const result = await PortfolioService.create(values);
    if (result.status) {
      await PortfolioService.reload();
      const portfolioId = _get(result.data, 'id');
      if (portfolioId) {
        navigate(`/portfolio/${portfolioId}`);
      }
      return undefined;
    }
    return parseFormErrors(result);
  }, [navigate]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.navigation}>
        <Link to="/"><Button>{t('listButton')}</Button></Link>
      </div>
      <div>
        <PortfolioForm onSubmit={onSubmit} initialValues={defaultValues} />
      </div>
    </div>
  );
}

export default CreatePortfolioPage;

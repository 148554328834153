import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Sync } from '@egjs/flicking-plugins';
import Flicking from '@egjs/react-flicking';
import BaseDialog from '../BaseDialog/BaseDialog';
import ProjectMedia from '../ProjectMedia/ProjectMedia';
import styles from './ProjectDialog.module.scss';
import { MEDIA_TYPES } from '../../const';

function ProjectDialog({
  media, className, showDialog, onClose, title, description,
}) {
  const flicking0 = useRef();
  const flicking1 = useRef();
  const [plugins, setPlugins] = useState([]);

  useEffect(() => {
    if (media.length) {
      setTimeout(() => {
        setPlugins([new Sync({
          type: 'index',
          synchronizedFlickingOptions: [
            {
              flicking: flicking0.current,
              isSlidable: true,
            },
            {
              flicking: flicking1.current,
              isClickable: true,
              activeClass: styles.active,
            },
          ],
        })]);
      }, 0);
    } else {
      setPlugins([]);
    }
  }, [media]);

  return (
    <BaseDialog
      className={classNames(styles.dialog, className)}
      showDialog={showDialog}
      onClose={onClose}
      liteClose
    >
      {media.length === 0 ? <ProjectMedia /> : (
        <>
          <Flicking
            ref={flicking0}
            plugins={plugins}
            renderOnlyVisible
            circular
          >
            {media.map(({ url, type }) => (
              <ProjectMedia
                key={url}
                autoPlay
                muted={false}
                className={styles.dialog__mainImage}
                mediaType={type}
                mediaUrl={url}
              />
            ))}
          </Flicking>
          <Flicking
            ref={flicking1}
            className={styles.dialog__smallImages}
            moveType="freeScroll"
            align="prev"
            bound
          >
            {media.map(({ url, type }) => (
              <ProjectMedia
                key={url}
                className={styles.dialog__smallImage}
                mediaType={type}
                mediaUrl={url}
              />
            ))}
          </Flicking>
        </>
      )}
      <div className={styles.dialog__title}>{title}</div>
      <div className={styles.dialog__description}>{description}</div>
    </BaseDialog>
  );
}

ProjectDialog.propTypes = {
  showDialog: PropTypes.bool.isRequired,
  media: PropTypes.arrayOf(PropTypes.shape({
    url: PropTypes.string.isRequired,
    type: PropTypes.oneOf(Object.values(MEDIA_TYPES)).isRequired,
  })),
  title: PropTypes.string,
  description: PropTypes.string,
  className: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

ProjectDialog.defaultProps = {
  media: [],
  className: '',
  title: '',
  description: '',
};

export default ProjectDialog;

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import _map from 'lodash/map';
import { useSearchParams } from 'react-router-dom';
import { getPortfolioDetails } from '../../DataCore/Store/Portfolio/slice';
import styles from './SharedPortfolioPage.module.scss';
import ProjectPage from '../../Common/Components/ProjectPage/ProjectPage';
import ProjectDialog from '../../Common/Components/ProjectDialog/ProjectDialog';
import PortfolioService from '../../DataCore/Services/PortfolioService';

function SharedPortfolioPage() {
  const { t } = useTranslation('translation', { keyPrefix: 'portfolioDetails' });
  const portfolio = useSelector(getPortfolioDetails);
  const [showProject, setShowProject] = useState();

  const [params] = useSearchParams();
  const routeShareLinkId = params.get('shareLinkId');

  useEffect(() => {
    if (routeShareLinkId) {
      PortfolioService.loadDetailsByShareLinkId(routeShareLinkId);
    }
    return () => {
      PortfolioService.clearDetails();
    };
  }, [routeShareLinkId]);

  return (
    <div className={styles.wrapper}>
      { portfolio?.projects.length === 0 ? (
        <div className={styles.emptyList}>
          {t('emptyList')}
        </div>
      ) : null }
      <div className={styles.projects}>
        {_map(
          portfolio?.projects,
          (project) => (
            <ProjectPage
              key={project.id}
              className={styles.project__page}
              title={project.title}
              description={project.description}
              media={project.mediaUrls}
              onClick={() => setShowProject(project)}
            />
          ),
        )}
      </div>
      <ProjectDialog
        showDialog={!!showProject}
        onClose={() => setShowProject(undefined)}
        media={showProject?.mediaUrls}
        title={showProject?.title}
        description={showProject?.description}
      />
    </div>
  );
}

export default SharedPortfolioPage;

import React from 'react';
import PropTypes from 'prop-types';
import { Form as FinalForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import styles from './PortfolioForm.module.scss';
import FieldInput from '../FieldInput/FieldInput';
import { requiredValidate } from '../../validators';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import { ReactComponent as DeleteSvg } from '../../Images/Delete.svg';
import IconButton from '../IconButton/IconButton';

function PortfolioForm({
  initialValues, onSubmit, onDeletePortfolio,
}) {
  const { t } = useTranslation('translation');

  return (
    <FinalForm
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, submitError, dirty }) => (
        <form onSubmit={handleSubmit} className={styles.form}>
          <IconButton
            className={styles.form__delete_project}
            onClick={onDeletePortfolio}
          >
            <DeleteSvg />
          </IconButton>
          <h2 className={styles.form__title}>
            {t('portfolioForm.formTitle')}
            {dirty ? ' *' : null}
          </h2>
          <FieldInput
            fieldName="title"
            label={t('portfolioForm.title')}
            placeholder={t('portfolioForm.titlePlaceholder')}
            validate={requiredValidate}
          />
          <FieldInput
            fieldName="imageUrl"
            label={t('portfolioForm.imageUrl')}
            placeholder={t('portfolioForm.imageUrlPlaceholder')}
          />
          <FieldInput
            fieldName="userUrl"
            label={t('portfolioForm.userUrl')}
            placeholder={t('portfolioForm.userUrlPlaceholder')}
          />
          <ErrorMessage content={submitError ? t(submitError) : undefined} />
          <button
            type="submit"
            className={styles.form__submit}
          >
            {t('portfolioForm.submitTitle')}
          </button>
        </form>
      )}
    />
  );
}

PortfolioForm.propTypes = {
  initialValues: PropTypes.object,
  onDeletePortfolio: PropTypes.func,
  onSubmit: PropTypes.func,
};

PortfolioForm.defaultProps = {
  initialValues: undefined,
  onDeletePortfolio: undefined,
  onSubmit: undefined,
};

export default PortfolioForm;

import React, { useEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import PortfolioService from '../../DataCore/Services/PortfolioService';

function PortfolioDetailsPage() {
  const { portfolioId: routePortfolioId } = useParams();

  useEffect(() => {
    if (routePortfolioId) {
      PortfolioService.loadDetails(routePortfolioId);
    }
    return () => {
      PortfolioService.clearDetails();
    };
  }, [routePortfolioId]);

  return (
    <Outlet />
  );
}

export default PortfolioDetailsPage;

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ReactComponent as PlusSvg } from '../../Common/Images/Plus.svg';
import PortfolioService from '../../DataCore/Services/PortfolioService';
import { getLoadingPortfolios, getPortfolios } from '../../DataCore/Store/Portfolio/slice';
import PortfolioPage from '../../Common/Components/PortfolioPage/PortfolioPage';
import styles from './MainPage.module.scss';

function MainPage() {
  const portfolios = useSelector(getPortfolios);
  const loadingPortfolios = useSelector(getLoadingPortfolios);
  const { t } = useTranslation('translation', { keyPrefix: 'mainPage' });

  useEffect(() => {
    PortfolioService.reload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.content}>
      <div className={styles.list}>
        {portfolios.map((portfolio) => (
          <Link to={`/portfolio/${portfolio.id}`} key={portfolio.id}>
            <PortfolioPage
              className={styles.portfolio}
              title={portfolio.title}
              imageUrl={portfolio.imageUrl}
            />
          </Link>
        ))}
      </div>
      {!loadingPortfolios && !portfolios.length ? t('emptyList') : null}
      <Link to="/portfolio">
        <div className={styles.fab}>
          <PlusSvg />
        </div>
      </Link>
    </div>
  );
}

export default MainPage;

import React from 'react';
import PropTypes from 'prop-types';
import BaseDialog from '../BaseDialog/BaseDialog';
import styles from './ConfirmDeleteDialog.module.scss';

function ConfirmDeleteDialog({
  showDialog, onClose, title, message, confirmButtonText, cancelButtonText, onConfirm,
}) {
  return (
    <BaseDialog
      className={styles.dialog}
      showDialog={showDialog}
      showCloseButton={false}
      liteClose
      onClose={onClose}
    >
      <h2 className={styles.header}>{title}</h2>
      <p className={styles.text}>{message}</p>
      <div className={styles.actions}>
        <button
          type="button"
          className={`${styles.button} ${styles.buttonCancel}`}
          onClick={onClose}
        >
          {cancelButtonText}
        </button>
        <button
          type="button"
          className={`${styles.button} ${styles.buttonConfirm}`}
          onClick={onConfirm}
        >
          {confirmButtonText}
        </button>
      </div>
    </BaseDialog>
  );
}

ConfirmDeleteDialog.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  confirmButtonText: PropTypes.string.isRequired,
  cancelButtonText: PropTypes.string.isRequired,
  showDialog: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default ConfirmDeleteDialog;

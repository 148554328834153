import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import classNames from 'classnames';
import styles from './FieldInput.module.scss';

function FieldInput({
  fieldName, label, placeholder, className, validate,
}) {
  return (
    <Field name={fieldName} validate={validate}>
      {({ input, meta }) => (
        <label
          htmlFor={fieldName}
          className={classNames(
            styles.field,
            { [styles.field_error]: meta.touched && (meta.error || meta.submitError) },
            className,
          )}
        >
          {label}
          <input
            id={fieldName}
            className={classNames(
              styles.field__input,
              { [styles.field__input_margin]: !!label },
            )}
            placeholder={placeholder}
            {...input}
          />
          <div className={styles.field__errorMessage}>{meta.error ?? meta.submitError}</div>
        </label>
      )}
    </Field>
  );
}

FieldInput.propTypes = {
  fieldName: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  className: PropTypes.string,
  validate: PropTypes.func,
};

FieldInput.defaultProps = {
  label: undefined,
  className: '',
  validate: undefined,
};

export default FieldInput;

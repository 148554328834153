import HttpClient from './HttpClient';
import parseResponse from './parseResponse';
import Feedback from '../../Models/Feedback';

export default class FeedbackRepository {
  static async create(feedback) {
    return HttpClient.post('feedbacks', new Feedback(feedback).toApi()).then(
      (response) => parseResponse(response, Feedback.fromApi),
      ({ response }) => parseResponse(response),
    );
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './PortfolioPage.module.scss';
import imgPlaceholder from '../../Images/imgPlaceholder.svg';

function PortfolioPage({ title, imageUrl, className }) {
  return (
    <div className={classNames(styles.front_page, className)}>
      <div className={styles.front_page__ratio_box}>
        <div className={styles.front_page__content}>
          <div className={styles.front_page__title_box}>
            <div className={styles.front_page__title}>
              { title }
            </div>
          </div>
          <div className={styles.front_page__image}>
            <img src={imageUrl || imgPlaceholder} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}

PortfolioPage.propTypes = {
  title: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  className: PropTypes.string,
};

PortfolioPage.defaultProps = {
  className: '',
};

export default PortfolioPage;

import store from '../Store/store';
import TokenRepository from '../Repositories/LocalStorage/TokenRepository';
import { removeToken, setToken } from '../Store/Session/slice';
import { resetPortfolioState } from '../Store/Portfolio/slice';

export default class AuthService {
  static logIn(token) {
    TokenRepository.save(token);
    store.dispatch(setToken(token));

    return { status: true };
  }

  static logOut() {
    TokenRepository.remove();
    store.dispatch(removeToken());
    store.dispatch(resetPortfolioState());

    return { status: true };
  }
}

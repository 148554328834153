import HttpClient from './HttpClient';
import parseResponse from './parseResponse';
import ShareLink from '../../Models/ShareLink';

export default class ShareLinkRepository {
  static async create(portfolio, shareLink) {
    return HttpClient.post(`portfolios/${portfolio.id}/shareLinks`, new ShareLink(shareLink).toApi()).then(
      (response) => parseResponse(response, ShareLink.fromApi),
      ({ response }) => parseResponse(response),
    );
  }

  static async delete(portfolio, shareLink) {
    return HttpClient.delete(`portfolios/${portfolio.id}/shareLinks/${shareLink.id}`).then(
      (response) => parseResponse(response),
      ({ response }) => parseResponse(response),
    );
  }
}

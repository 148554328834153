import React from 'react';
import PropTypes from 'prop-types';
import { Dialog } from '@reach/dialog';
import '@reach/dialog/styles.css';
import './BaseDialog.css';
import styles from './BaseDialog.module.scss';
import { ReactComponent as CloseSvg } from '../../Images/Close.svg';

function BaseDialog({
  showDialog, showCloseButton, liteClose, onClose, className, children,
}) {
  return (
    <Dialog isOpen={showDialog} aria-label="Dialog" onDismiss={liteClose ? onClose : undefined}>
      <div className={`${styles.dialog} ${className}`}>
        {children}
        {showCloseButton && (
          <button className={styles.closeDialogButton} type="button" onClick={onClose}>
            <CloseSvg />
          </button>
        )}
      </div>
    </Dialog>
  );
}

BaseDialog.propTypes = {
  showDialog: PropTypes.bool.isRequired,
  showCloseButton: PropTypes.bool,
  liteClose: PropTypes.bool,
  onClose: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.array,
    PropTypes.string,
  ]).isRequired,
};

BaseDialog.defaultProps = {
  className: '',
  showCloseButton: true,
  liteClose: false,
  onClose: undefined,
};

export default BaseDialog;

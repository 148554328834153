const ID = 'id';
const TEMPLATE = 'template';
const LINK = 'link';

export default class ShareLink {
  constructor(parameters) {
    const {
      id,
      template,
      link,
    } = parameters;
    this.id = id;
    this.template = template;
    this.link = link;
  }

  static fromApi(object = {}) {
    return new ShareLink(
      {
        id: object[ID],
        template: object[TEMPLATE],
        link: object[LINK],
      },
    );
  }

  toApi() {
    return {
      [ID]: this.id,
      [TEMPLATE]: this.template,
      [LINK]: this.link,
    };
  }
}

import _invoke from 'lodash/invoke';
import _get from 'lodash/get';
import HttpClient from './HttpClient';
import Portfolio from '../../Models/Portfolio';
import parseResponse from './parseResponse';
import { getPageSize } from './func';

export default class PortfolioRepository {
  static async getAllByPage(numberPage) {
    return HttpClient.get('portfolios', {
      params: { index: numberPage, pageSize: getPageSize() },
    }).then(
      (response) => parseResponse(response, (data) => {
        const portfolios = _invoke(data, 'data.map', Portfolio.fromApi) ?? [];
        const portfoliosTotal = _get(data, 'itemsTotal');
        return {
          portfolios,
          portfoliosTotal,
        };
      }),
      ({ response }) => parseResponse(response),
    );
  }

  static async getById(portfolioId) {
    return HttpClient.get(`portfolios/${portfolioId}`).then(
      (response) => parseResponse(response, Portfolio.fromApi),
      ({ response }) => parseResponse(response),
    );
  }

  static async getByShareLinkId(shareLinkId) {
    return HttpClient.get(`sharePortfolios/${shareLinkId}`).then(
      (response) => parseResponse(response, Portfolio.fromApi),
      ({ response }) => parseResponse(response),
    );
  }

  static async create(portfolio) {
    return HttpClient.post('portfolios', new Portfolio(portfolio).toApi()).then(
      (response) => parseResponse(response, Portfolio.fromApi),
      ({ response }) => parseResponse(response, undefined, Portfolio.parseErrors),
    );
  }

  static async update(portfolio) {
    return HttpClient.put(`portfolios/${portfolio.id}`, new Portfolio(portfolio).toApi()).then(
      (response) => parseResponse(response, Portfolio.fromApi),
      ({ response }) => parseResponse(response, undefined, Portfolio.parseErrors),
    );
  }

  static async delete(portfolio) {
    return HttpClient.delete(`portfolios/${portfolio.id}`).then(
      (response) => parseResponse(response),
      ({ response }) => parseResponse(response),
    );
  }
}

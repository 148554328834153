import _invoke from 'lodash/invoke';
import _map from 'lodash/map';
import Media from './Media';

const ID = 'id';
const TITLE = 'title';
const DESCRIPTION = 'description';
const PROJECT_URL = 'projectUrl';
const MEDIA_URLS = 'media';

const ERROR_TITLE = 'Title';
const ERROR_DESCRIPTION = 'Description';
const ERROR_PROJECT_URL = 'ProjectUrl';
const ERROR_IMAGE_URLS = 'Media';

export default class Project {
  constructor(parameters) {
    const {
      id,
      title,
      description,
      projectUrl,
      mediaUrls,
    } = parameters;
    this.id = id;
    this.title = title;
    this.description = description;
    this.projectUrl = projectUrl;
    this.mediaUrls = _map(mediaUrls, (media) => new Media(media));
  }

  toApi() {
    return {
      [ID]: this.id,
      [TITLE]: this.title,
      [DESCRIPTION]: this.description,
      [PROJECT_URL]: this.projectUrl,
      [MEDIA_URLS]: _invoke(this.mediaUrls, 'map', (media) => media.toApi()),
    };
  }

  static fromApi(object = {}) {
    return new Project(
      {
        id: object[ID],
        title: object[TITLE],
        description: object[DESCRIPTION],
        projectUrl: object[PROJECT_URL],
        mediaUrls: _invoke(object, [MEDIA_URLS, 'map'], Media.fromApi),
      },
    );
  }

  static parseErrors(object = {}) {
    return {
      title: object[ERROR_TITLE],
      description: object[ERROR_DESCRIPTION],
      projectUrl: object[ERROR_PROJECT_URL],
      mediaUrls: object[ERROR_IMAGE_URLS],
    };
  }
}

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import _map from 'lodash/map';
import PortfolioService from '../../DataCore/Services/PortfolioService';
import { parseFormErrors } from '../../Common/func';
import PortfolioForm from '../../Common/Components/PortfolioForm/PortfolioForm';
import { getPortfolioDetails } from '../../DataCore/Store/Portfolio/slice';
import styles from './EditPortfolioPage.module.scss';
import Button from '../../Common/Components/Button/Button';
import ProjectForm from '../../Common/Components/ProjectForm/ProjectForm';
import ProjectService from '../../DataCore/Services/ProjectService';
import ConfirmDeleteDialog from '../../Common/Components/ConfirmDialog/ConfirmDeleteDialog';

const handlePortfolioSubmit = async (values) => {
  const result = await PortfolioService.update(values);
  if (result.status) {
    return undefined;
  }
  return parseFormErrors(result);
};

function EditPortfolioPage() {
  const { t } = useTranslation('translation', { keyPrefix: 'portfolioDetails' });
  const portfolio = useSelector(getPortfolioDetails);
  const [addNewPage, setAddNewPage] = useState(false);
  const navigate = useNavigate();
  const [showDeletePortfolioDialog, setShowDeletePortfolioDialog] = useState(false);
  const [deleteProject, setDeleteProject] = useState();

  const handleCreateProjectSubmit = async (values) => {
    const result = await ProjectService.create(portfolio.id, values);
    if (result.status) {
      setAddNewPage(false);
      return undefined;
    }
    return parseFormErrors(result);
  };

  const handleUpdateProjectSubmit = async (values) => {
    const result = await ProjectService.update(portfolio.id, values);
    if (result.status) {
      return undefined;
    }
    return parseFormErrors(result);
  };

  const handleDeleteProject = async () => {
    if (deleteProject) {
      await ProjectService.delete(portfolio.id, deleteProject);
    }
    setDeleteProject(undefined);
  };

  const handleDeletePortfolio = async () => {
    const result = await PortfolioService.delete(portfolio);
    if (result.status) {
      navigate('/');
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.navigation}>
        <Link to="/"><Button>{t('listButton')}</Button></Link>
        {portfolio ? <Link to={`/portfolio/${portfolio.id}`}><Button>{t('viewButton')}</Button></Link> : null}
      </div>
      {portfolio ? (
        <div className={styles.content}>
          <PortfolioForm
            initialValues={portfolio}
            onDeletePortfolio={() => setShowDeletePortfolioDialog(true)}
            onSubmit={handlePortfolioSubmit}
          />
          {_map(
            portfolio.projects,
            (project) => (
              <ProjectForm
                key={project.id}
                initialValues={project}
                projectName={project.title}
                onDeleteProject={() => setDeleteProject(project)}
                onSubmit={handleUpdateProjectSubmit}
              />
            ),
          )}
          { addNewPage
            ? (
              <ProjectForm
                initialValues={{
                  projectUrl: '',
                }}
                onDeleteProject={() => setAddNewPage(false)}
                onSubmit={handleCreateProjectSubmit}
              />
            )
            : (
              <button
                type="button"
                className={styles.add_new_page}
                onClick={() => setAddNewPage(true)}
              >
                { t('addNewPage') }
              </button>
            )}
        </div>
      ) : null}
      <ConfirmDeleteDialog
        showDialog={showDeletePortfolioDialog}
        title={t('deletePortfolioTitle')}
        message={t('deletePortfolioMessage')}
        cancelButtonText={t('deleteCancel')}
        confirmButtonText={t('deleteConfirm')}
        onClose={() => setShowDeletePortfolioDialog(false)}
        onConfirm={handleDeletePortfolio}
      />
      <ConfirmDeleteDialog
        showDialog={!!deleteProject}
        title={t('deleteProjectTitle')}
        message={t('deleteProjectMessage')}
        cancelButtonText={t('deleteCancel')}
        confirmButtonText={t('deleteConfirm')}
        onClose={() => setDeleteProject(undefined)}
        onConfirm={handleDeleteProject}
      />
    </div>
  );
}

export default EditPortfolioPage;

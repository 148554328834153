import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './Button.module.scss';

function Button({
  innerRef, className, onClick, children,
}) {
  return (
    <button
      ref={innerRef}
      type="button"
      onClick={onClick}
      className={classNames(styles.button, className)}
    >
      { children }
    </button>
  );
}

Button.propTypes = {
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  className: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.array,
    PropTypes.string,
  ]),
};

Button.defaultProps = {
  innerRef: undefined,
  className: '',
  onClick: undefined,
  children: undefined,
};

export default Button;

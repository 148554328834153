import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import _map from 'lodash/map';
import { getPortfolioDetails } from '../../DataCore/Store/Portfolio/slice';
import styles from './ViewPortfolioPage.module.scss';
import Button from '../../Common/Components/Button/Button';
import ProjectPage from '../../Common/Components/ProjectPage/ProjectPage';
import ProjectDialog from '../../Common/Components/ProjectDialog/ProjectDialog';

function ViewPortfolioPage() {
  const { t } = useTranslation('translation', { keyPrefix: 'portfolioDetails' });
  const portfolio = useSelector(getPortfolioDetails);
  const [showProject, setShowProject] = useState();

  return (
    <div className={styles.wrapper}>
      <div className={styles.navigation}>
        <Link to="/"><Button>{t('listButton')}</Button></Link>
        {portfolio ? <Link to={`/portfolio/${portfolio.id}/edit`}><Button>{t('editButton')}</Button></Link> : null }
      </div>
      { portfolio?.projects.length === 0 ? (
        <div className={styles.emptyList}>
          {t('emptyList')}
        </div>
      ) : null }
      <div className={styles.projects}>
        {_map(
          portfolio?.projects,
          (project) => (
            <ProjectPage
              key={project.id}
              className={styles.project__page}
              title={project.title}
              description={project.description}
              media={project.mediaUrls}
              onClick={() => setShowProject(project)}
            />
          ),
        )}
      </div>
      <ProjectDialog
        showDialog={!!showProject}
        onClose={() => setShowProject(undefined)}
        media={showProject?.mediaUrls}
        title={showProject?.title}
        description={showProject?.description}
      />
    </div>
  );
}

export default ViewPortfolioPage;

const URL = 'url';
const TYPE = 'type';

export default class Media {
  constructor(parameters) {
    const {
      url,
      type,
    } = parameters;
    this.url = url;
    this.type = type;
  }

  toApi() {
    return {
      [URL]: this.url,
      [TYPE]: this.type,
    };
  }

  static fromApi(object = {}) {
    return new Media(
      {
        url: object[URL],
        type: object[TYPE],
      },
    );
  }
}

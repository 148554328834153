import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form as FinalForm } from 'react-final-form';
import { useSearchParams } from 'react-router-dom';
import styles from './RequestLoginPage.module.scss';
import { ReactComponent as LogoSvg } from '../../Common/Images/Logo.svg';
import FieldInput from '../../Common/Components/FieldInput/FieldInput';
import { requiredValidate } from '../../Common/validators';
import ErrorMessage from '../../Common/Components/ErrorMessage/ErrorMessage';
import { parseFormErrors } from '../../Common/func';
import FeedbackService from '../../DataCore/Services/FeedbackService';
import AuthService from '../../DataCore/Services/AuthService';

const handleSaveFeedback = async (values) => {
  const result = await FeedbackService.create({
    description: JSON.stringify(values),
  });
  if (result.status) {
    return undefined;
  }
  return parseFormErrors(result);
};

function RequestLoginPage() {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('Authorization');
  const { t } = useTranslation('translation');

  if (token) {
    AuthService.logIn(token);
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.mainBox}>
        <div className={styles.logoBox}>
          <LogoSvg className={styles.logo} />
        </div>
        <FinalForm
          onSubmit={handleSaveFeedback}
          render={({
            handleSubmit, submitError, submitting, submitSucceeded,
          }) => (
            <form
              onSubmit={handleSubmit}
              className={styles.form}
            >
              <div className={styles.description}>
                {t('requestLoginPage.description')}
              </div>
              <FieldInput
                fieldName="name"
                label={t('requestLoginPage.name')}
                placeholder={t('requestLoginPage.namePlaceholder')}
                validate={requiredValidate}
              />
              <FieldInput
                fieldName="email"
                label={t('requestLoginPage.email')}
                placeholder={t('requestLoginPage.emailPlaceholder')}
                validate={requiredValidate}
              />
              <FieldInput
                fieldName="request"
                label={t('requestLoginPage.request')}
                placeholder={t('requestLoginPage.requestPlaceholder')}
                validate={requiredValidate}
              />
              <ErrorMessage content={submitError ? t(submitError) : undefined} />
              { submitSucceeded ? (
                <div className={styles.form__submitSucceeded}>
                  {t('requestLoginPage.submitSucceeded')}
                </div>
              ) : (
                <button
                  type="submit"
                  className={styles.form__submit}
                  disabled={submitting}
                >
                  {t('requestLoginPage.submitButton')}
                </button>
              ) }
            </form>
          )}
        />
      </div>
    </div>
  );
}

export default RequestLoginPage;

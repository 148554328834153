import ProjectRepository from '../Repositories/Api/ProjectRepository';
import PortfolioRepository from '../Repositories/Api/PortfolioRepository';
import store from '../Store/store';
import { setPortfolioDetails } from '../Store/Portfolio/slice';

export default class ProjectService {
  static async create(portfolioId, project) {
    const result = await ProjectRepository.create(portfolioId, project);
    if (result.status) {
      const portfolioDetailsResult = await PortfolioRepository.getById(portfolioId);
      if (portfolioDetailsResult.status) {
        store.dispatch(setPortfolioDetails(portfolioDetailsResult.data));
      }
    }
    return result;
  }

  static async update(portfolioId, project) {
    const result = await ProjectRepository.update(portfolioId, project);
    if (result.status) {
      const portfolioDetailsResult = await PortfolioRepository.getById(portfolioId);
      if (portfolioDetailsResult.status) {
        store.dispatch(setPortfolioDetails(portfolioDetailsResult.data));
      }
    }
    return result;
  }

  static async delete(portfolioId, project) {
    const result = await ProjectRepository.delete(portfolioId, project);
    if (result.status) {
      const portfolioDetailsResult = await PortfolioRepository.getById(portfolioId);
      if (portfolioDetailsResult.status) {
        store.dispatch(setPortfolioDetails(portfolioDetailsResult.data));
      }
    }
    return result;
  }
}

export const AUTHORIZATION_HEADER = 'authorization';

export const ERROR_TYPES = Object.freeze({
  BAD_REQUEST: 'BadRequest',
  UNAUTHORIZED: 'Unauthorized',
  FORBIDDEN: 'Forbidden',
  NOT_FOUND: 'NotFound',
  SERVER_ERROR: 'ServerError',
  NETWORK_ERROR: 'NetworkError',
  OTHER: 'Other',
});

export const TOAST_STATUS = Object.freeze({
  PRIMARY: 'PRIMARY',
  DEFAULT: 'DEFAULT',
  SUCCESS: 'SUCCESS',
  WARNING: 'WARNING',
  DANGER: 'DANGER',
});

export const LOGIN_PROVIDER_TYPES = Object.freeze({
  GOOGLE: 'Google',
  TELEGRAM: 'Telegram',
});

export const MEDIA_TYPES = Object.freeze({
  IMAGE: 'Image',
  VIDEO: 'Video',
});

export const PORTFOLIO_TEMPLATES = Object.freeze({
  MAIN: 'main',
});

import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _get from 'lodash/get';
import { useSelector } from 'react-redux';
import { Link, useMatch } from 'react-router-dom';
import { existingLanguages } from '../../../i18n';
import { islLogIn } from '../../../DataCore/Store/Session/slice';
import IconButton from '../IconButton/IconButton';
import styles from './Header.module.scss';
import { ReactComponent as LogoSvg } from '../../Images/Logo.svg';
import { ReactComponent as LogOutSvg } from './LogOut.svg';
import { ReactComponent as LinkSvg } from '../../Images/Link.svg';
import AuthService from '../../../DataCore/Services/AuthService';
import { getPortfolioDetails } from '../../../DataCore/Store/Portfolio/slice';
import PortfolioShareDialog from '../PortfolioShareDialog/PortfolioShareDialog';

const handleLogOut = () => {
  AuthService.logOut();
};

function Header() {
  const { t, i18n } = useTranslation('translation', { keyPrefix: 'header' });
  const isLogIn = useSelector(islLogIn);
  const isPortfolioDetailsPage = !!useMatch('/portfolio/:portfolioId');
  const isSharedPortfolioPage = !!useMatch('/SignIn/link');
  const portfolio = useSelector(getPortfolioDetails);
  const [showShareDialog, setShowShareDialog] = useState(false);
  const showPortfolioTitle = (isPortfolioDetailsPage || isSharedPortfolioPage) && portfolio;

  const handleChangeLocale = useCallback(async (event) => {
    const locale = _get(event, 'target.value');
    if (locale) {
      await i18n.changeLanguage(locale);
    }
  }, [i18n]);

  const handleShare = () => {
    setShowShareDialog(true);
  };

  return (
    <div className={styles.header}>
      <div className={styles.left}>
        <Link to="/"><LogoSvg className={styles.logo} /></Link>
        {showPortfolioTitle ? (
          <div className={styles.portfolioTitle}>
            <div>
              { portfolio.title }
            </div>
          </div>
        ) : null}
      </div>
      <div className={styles.options}>
        {isPortfolioDetailsPage && portfolio ? (
          <IconButton onClick={handleShare}><LinkSvg /></IconButton>
        ) : null}
        <select
          className={styles.languageSelector}
          onChange={handleChangeLocale}
          value={i18n.language}
        >
          {existingLanguages.map((language) => (
            <option key={language} value={language}>{t(language)}</option>
          ))}
        </select>
        {isLogIn ? <IconButton onClick={handleLogOut}><LogOutSvg /></IconButton> : null }
      </div>
      <PortfolioShareDialog
        showDialog={showShareDialog}
        portfolio={portfolio}
        onClose={() => setShowShareDialog(false)}
      />
    </div>
  );
}

export default Header;

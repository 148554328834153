import React, { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import MainPage from '../Main/MainPage';
import SignInPage from '../SignIn/SignInPage';
import RequireAuth from '../../Common/Components/RequireAuth';
import RequireNotAuth from '../../Common/Components/RequireNotAuth';
import ToastBox from '../../Common/Components/ToastBox/ToastBox';
import Header from '../../Common/Components/Header/Header';
import EditPortfolioPage from '../EditPortfolio/EditPortfolioPage';
import CreatePortfolioPage from '../CreatePortfolio/CreatePortfolioPage';
import AuthService from '../../DataCore/Services/AuthService';
import HttpClient from '../../DataCore/Repositories/Api/HttpClient';
import ViewPortfolioPage from '../ViewPortfolio/ViewPortfolioPage';
import PortfolioDetailsPage from '../PortfolioDetails/PortfolioDetailsPage';
import SharedPortfolioPage from '../SharedPortfolio/SharedPortfolioPage';
import RequestLoginPage from '../RequestLogin/RequestLoginPage';

const isPublicLogin = process.env.REACT_APP_PUBLIC_LOGIN === 'true';

function App() {
  useEffect(() => {
    HttpClient.setUnauthorizedListener(() => {
      AuthService.logOut();
    });
    return () => {
      HttpClient.setUnauthorizedListener(undefined);
    };
  }, []);

  const LoginComponent = isPublicLogin ? SignInPage : RequestLoginPage;

  return (
    <div>
      <Header />
      <Routes>
        <Route path="/" element={<RequireAuth><MainPage /></RequireAuth>} />
        <Route path="/portfolio" element={<RequireAuth><CreatePortfolioPage /></RequireAuth>} />
        <Route path="/portfolio/:portfolioId" element={<RequireAuth><PortfolioDetailsPage /></RequireAuth>}>
          <Route index element={<ViewPortfolioPage />} />
          <Route path="edit" element={<EditPortfolioPage />} />
        </Route>
        <Route path="/SignIn" element={<RequireNotAuth><LoginComponent /></RequireNotAuth>} />
        <Route path="/SignIn/link" element={<SharedPortfolioPage />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      <ToastBox />
    </div>
  );
}

export default App;

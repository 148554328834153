import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import _get from 'lodash/get';
import BaseDialog from '../BaseDialog/BaseDialog';
import styles from './PortfolioShareDialog.module.scss';
import Button from '../Button/Button';
import PortfolioService from '../../../DataCore/Services/PortfolioService';
import ShareLink from '../../../DataCore/Models/ShareLink';
import { PORTFOLIO_TEMPLATES } from '../../const';
import LinkRow from './LinkRow';
import ConfirmDeleteDialog from '../ConfirmDialog/ConfirmDeleteDialog';

const maxProjectShareLinks = +process.env.REACT_APP_MAX_PROJECT_SHARE_LINKS || 10;

function PortfolioShareDialog({
  portfolio, className, showDialog, onClose,
}) {
  const [deleteLink, setDeleteLink] = useState();
  const { t } = useTranslation('translation', { keyPrefix: 'projectShareDialog' });

  const handleCreateShareLink = async () => {
    await PortfolioService.createShareLink(portfolio, new ShareLink({
      template: PORTFOLIO_TEMPLATES.MAIN,
    }));
  };

  const handleDeleteShareLink = async () => {
    if (deleteLink) {
      await PortfolioService.deleteShareLink(portfolio, deleteLink);
    }
    setDeleteLink(undefined);
  };

  const handleCopyShareLink = async (shareLink) => {
    if (!navigator.clipboard) {
      return;
    }
    await navigator.clipboard.writeText(shareLink.link);
  };

  const handleChangeAccess = async (event) => {
    const isPublic = _get(event, 'target.value') === 'true';
    await PortfolioService.update({
      ...portfolio,
      isPublic,
    });
  };

  return (
    <BaseDialog
      className={classNames(styles.dialog, className)}
      showDialog={showDialog}
      onClose={onClose}
      liteClose
    >
      <h2 className={styles.header}>{t('title')}</h2>
      {portfolio?.shareLinks.length === 0 ? (
        <div className={styles.emptyMessage}>
          {t('emptyShareLinkList')}
        </div>
      ) : null}
      <div className={styles.links}>
        {portfolio?.shareLinks.map((shareLink) => (
          <LinkRow
            key={shareLink.id}
            link={shareLink.link}
            onCopy={() => handleCopyShareLink(shareLink)}
            onDelete={() => setDeleteLink(shareLink)}
          />
        ))}
      </div>
      {portfolio?.shareLinks.length <= maxProjectShareLinks ? (
        <div className={styles.footer}>
          <select
            className={styles.accessSelector}
            value={portfolio.isPublic}
            onChange={handleChangeAccess}
          >
            <option value={false}>{t('privateShareLinks')}</option>
            <option value>{t('publicShareLinks')}</option>
          </select>
          <Button onClick={handleCreateShareLink}>{t('createShareLinkButton')}</Button>
        </div>
      ) : null}
      <ConfirmDeleteDialog
        showDialog={!!deleteLink}
        title={t('deleteLinkTitle')}
        message={t('deleteLinkMessage')}
        cancelButtonText={t('deleteLinkCancel')}
        confirmButtonText={t('deleteLinkConfirm')}
        onClose={() => setDeleteLink(undefined)}
        onConfirm={handleDeleteShareLink}
      />
    </BaseDialog>
  );
}

PortfolioShareDialog.propTypes = {
  portfolio: PropTypes.shape({
    id: PropTypes.number.isRequired,
    isPublic: PropTypes.bool.isRequired,
    shareLinks: PropTypes.array.isRequired,
  }),
  showDialog: PropTypes.bool.isRequired,
  className: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

PortfolioShareDialog.defaultProps = {
  portfolio: undefined,
  className: '',
};

export default PortfolioShareDialog;

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import _toPairs from 'lodash/toPairs';

function getCurrentLanguage(existingLanguages = []) {
  const browserLanguage = window.navigator.languages
    .find((locale) => existingLanguages.includes(locale));
  if (browserLanguage) {
    return browserLanguage;
  }

  return existingLanguages[0];
}

function loadLanguageTranslations() {
  const appLanguages = process.env.REACT_APP_I18N_LANGUAGES;
  const languages = require.context('./translations', false, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages = {};
  languages.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const language = matched[1];
      if (appLanguages.includes(language)) {
        messages[language] = languages(key);
      }
    }
  });
  return messages;
}

const messages = loadLanguageTranslations();
const existingLanguages = Object.keys(messages);
const currentLanguage = getCurrentLanguage(existingLanguages);
const resources = _toPairs(messages).reduce((previousValue, [locale, translation]) => ({
  ...previousValue,
  [locale]: {
    translation,
  },
}), {});

i18n.use(initReactI18next)
  .init({
    resources,
    lng: currentLanguage,
    fallbackLng: process.env.REACT_APP_I18N_FALLBACK_LANGUAGE,
    interpolation: {
      escapeValue: false,
    },
  });

export {
  existingLanguages,
  i18n,
};

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './ErrorMessage.module.scss';

function ErrorMessage({
  className, content,
}) {
  return (
    <div className={classNames(styles.box, className)}>
      {content}
    </div>
  );
}

ErrorMessage.propTypes = {
  className: PropTypes.string,
  content: PropTypes.string,
};

ErrorMessage.defaultProps = {
  className: '',
  content: '',
};

export default ErrorMessage;
